import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/00.jpg'
// core components

function Slide1 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });



  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
        style={{
        
         backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter "  />
        <Container className='motto'>
         
        <Row >
         
        <Col md={'7'}>
             <h2 style={{color:'white',fontWeight:'bold'}}>
             INTRODUCING THE <br/> TECHNOLOGY OF TOMORROW.
             </h2>
             </Col>
           
             <Col md={'7'}>
             <h3  style={{color:'white',fontWeight:400}}>
             CLOUD & ANTICIPATORY LOGISTICS
             </h3>
          
             <br/>
             </Col>
           <Col md={'7'}>
           <h5  style={{color:'white'}}>
             With the power of predictive algorithms, driven by artificial intelligence and machine learning, fed by big data through intelligent assets or the Internet of Things (IoT) we integrate cloud computing with supply chain to help our clients overcome complex, volatile environments, and to offer them customizable, modular on-demand services.
             </h5>
             </Col>
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide1;
