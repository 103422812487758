import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import image from '../../assets/img/IoT.jpg'
// core components

function Slide4(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
                INCREASED<br />
                PRODUCTIVITY
              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
                DIRECTING FINITE TALENT & RESOURCES TO AREAS THAT CAN GENERATE THE MOST VALUE.
              </h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
                By combining data from time-sheet records, employee profiles, as well as offline data sources like geographical footprint, team composition, and workflow fragmentation,
                <br />
                <br />
                We can test different hypotheses to increase productivity. Live dashboards and digitised tracking tools can surely drive superior results.

              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide4;
