import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/mRnrt0.jpg'
// core components

function Slide2 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });



  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
        style={{
        
         backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
         
        <Row >
         
        <Col md={'8'} className="motto">
             <h2 style={{color:'white',fontWeight:'bold'}}>
             FLEET MANAGEMENT
             </h2>
             </Col>
             <Col md={'7'} className="motto">
             <h3 style={{color:'white',fontWeight:'bold'}}>
             A SMARTER APPROACH TO GROWING YOUR FLEET.
             </h3>
             <br/>
             </Col>
           <Col md={'7'} className="motto">
           <h5  style={{color:'white'}}>
           The traditional approach to handling business growth is to focus on meeting an increased demand: more workers, more vehicles and more warehouses. Our fleet management technology, helps mobile workforces and the people who manage them get smarter about how they use their assets.
         </h5>
             </Col>
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide2;
