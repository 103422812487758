import React, { useState } from 'react'
import {
    Button,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
  } from "reactstrap";
  import Axios from 'axios'
  import {  db } from '../../firebase/firebaseConfig'
import './styles.css'


 const  ContactUs = () => {
  const [formData, setFormData] = useState({})

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }
  const handleSubmit = event => {
    event.preventDefault()
    sendEmail()
    setFormData({
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: '',
    })
  }
  const sendEmail = () => {
    Axios.post(
      'https://us-central1-fleetserve-data.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        db.collection('emails').add({
          firstName: formData.firstName,
          lastName: formData.lastName,
          company:formData.company,
          email: formData.email,
          message: formData.message,
          time: new Date(),
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  return (
    <div className="main  h-100" id='ContactUs' style={{height:'100vh'}}>
        <div className="section h-100">
        {/* <form onSubmit={handleSubmit}> */}
    <Container  >
      <Row style={{flex:1,justifyContent:'center'}}>
        <Col className="ml-auto mr-auto" md="8">
          <h2 className="text-center font" >Share your details and we'll be in touch</h2>
          <Form className="contact-form">
            <Row>
              <Col md="6">
                <label>First Name</label>
                <InputGroup>
                
                    <InputGroupText name="firstName" onChange={updateInput}>
                      <i className="nc-icon nc-single-02" />
                    </InputGroupText>
          
                  <Input value={formData?.firstName} placeholder="First Name" type="text" name="firstName"  onChange={updateInput}/>
                </InputGroup>
              </Col>
              <Col md="6">
                <label>Last Name</label>
                <InputGroup>
              
                    <InputGroupText name="lastName" onChange={updateInput}>
                      <i className="nc-icon  nc-single-02" />
                    </InputGroupText>
             
                  <Input value={formData?.lastName}  placeholder="Last Name" type="text" name="lastName"  onChange={updateInput}/>
                </InputGroup>
              </Col>
            </Row>
            <Row>
            <Col md="6">
                <label>Email</label>
                <InputGroup>
              
                    <InputGroupText   name="email" onChange={updateInput}>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
             
                  <Input value={formData?.email}  placeholder="Email" type="text" name="email"  onChange={updateInput} />
                </InputGroup>
              </Col>
              <Col md="6">
                
                <label>Company</label>
                <InputGroup>
                
                    <InputGroupText   >
                      <i className="nc-icon nc-bag-16" />
                    </InputGroupText>
          
                  <Input value={formData?.company} placeholder="Company" type="text" name="company"  onChange={updateInput}/>
                </InputGroup>
              </Col>
         
            </Row>
            <label>Message</label>
            <Input
              placeholder="Tell us your thoughts and feelings..."
              type="textarea"
              rows="4"
              name="message"
              onChange={updateInput}
              value={formData?.message}
            />
            <Row style={{flex:1,justifyContent:'center'}}>
              <Col className="ml-auto mr-auto" md="4" >
                <Button className="btn-fill" onClick={handleSubmit} type='submit' style={{backgroundColor:'#5fb47c',border:0}} size="lg">
                  Send Message
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
    {/* </form> */}
  </div>
  </div>
  )
}
export default ContactUs