import "bootstrap/scss/bootstrap.scss";
import 'bootstrap/dist/css/bootstrap.css';
import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image from '../../assets/img/Robotics.jpg'
// core components

function Slide5(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              EQUIPMENT PRODUCTIVITY
              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              IMPROVING ASSET & PROCESS PRODUCTIVITY THROUGH ANALYTICS
 </h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
              By gathering data on fleet management, work metrics, and production to get a better sense of the disparities in performance across assets, operations, and fleets, we combine data sets in an effort to uncover the links between certain processes, maintenance activities, and scheduling to determine how they affected overall productivity. 
<br/>
<br/>
Through analytics, we identify factors that were have a significant impact on the company’s productivity, such as absence of supervisors, low scheduled maintenance compliance, and increased unscheduled maintenance. Our analysis shows that usually too much preventative maintenance on new equipment could negatively affect uptime and productivity.

              </h5>
              {/* <Button
                            // onClick={() => {
                            //   executeScrollSlide5()
                            //   // executeScroll()
                            // }}
                            //   href="#ContactUs"
                            className="btn-round "
                            color="success"
                            target="_blank"
                            size="medium"
                            outline
                            style={{fontSize:8,marginRight:'10px'}}
                          > Read More <ArrowForwardIcon fontSize={'small'}/></Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide5;
