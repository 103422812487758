import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
  } from "reactstrap";
import image from '../../assets/img/unnamed-5.png'
import { useMediaQuery } from 'react-responsive'
// core components

function Slide11 (props) {
  let pageHeader = React.createRef();
  const {contactUsRef}=props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 924px)' })


  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });  
  return (
    <>
      <div
            className="page-header h-100"
        style={{
        backgroundColor:'white',
        height:'100vh'
        // / backgroundImage: `url(${image})`,
        }}
  
     
      >
  
        <Container>
         
        <Row >
         
        <Col md={'7'}>
           
         <h3 style={{fontWeight:'bold'}}>
           About Us:
         </h3>
         <br/>
           <h5  >
           Since 1969, working with with some of the world’s most demanding clients we have adapted to customer needs. We help our partners understand their costs better with monthly reporting and ensure they have the right trucks, tires, drivers and maintenance plans for higher availability, efficient fuel consumption and a lower costs and carbon footprint.
           <br/><br/>
Along with our clients we form one team with a unique goal better, safer and more reliable operations. In line with our commitment to provide world class services while protecting our people, the society and the environment we developed One Comprehensive Integrated Management System ISO 9001:2015, ISO 14001:2015 and OHSAS 18001:2007.
<br/><br/>
We respect the environment, society, our people and shareholders…

</h5>
<br/>
<h4 style={{fontWeight:'bold'}}>
Mission Statement:

</h4>
<br/>
<h5 >

Facilitating an agile transformation of small and medium size enterprises into data driven companies able to operate globally.
 
</h5>
             </Col>
           {isTabletOrMobile?null:
               <Col md={'5'}  className="h-100 align-self-end ">
               <img src={image} style={{width:'400px'}} />
               </Col>
           }
             </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide11;
