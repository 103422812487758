import React from "react";
import { createRef, useRef } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import image from '../../assets/img/DIGITAL.JPG'
// core components

function Slide7(props) {
  let pageHeader = React.createRef();
  const { contactUsRef } = props
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const executeScroll = () => contactUsRef.current.scrollIntoView({ behavior: "smooth" });
  return (
    <>
      <div
        style={{

          backgroundImage: `url(${image})`,
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >   <div className="filter" />
        <div />

        <Container className="motto ">

          <Row >

            <Col md={'8'}>
              <h2 style={{ color: 'white', fontWeight: 'bold' }}>
              PREDICTIVE MAINTENANCE


              </h2>
            </Col>
            <Col md={'7'}>
              <h3 style={{ color: 'white', fontWeight: 'bold' }}>
              IMPROVING EARLY FAILURE DETECTION & REDUCING MACHINE DOWN TIME.

 </h3>
              <br />
            </Col>
            <Col md={'6'}>
              <h5 style={{ color: 'white' }}>
              In any capital-intensive industry, productivity depends on keeping the machines running. Mechanical failures or unexpected maintenance can quickly bring an entire operation to a halt and every hour of downtime translates to lost profits.
<br/>
<br/>
Using proportional hazards models to calculate the probability of machinery failure using a range of factors, such as total number of operating hours, load volume, and weather, we create analytical models capable of detecting incipient failures, such as motor voltage, current, and temperature.

              </h5>
           
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Slide7;
